export const menuRoles = {
    itensMenu: {
        solicitacaoDeAcesso: {
            roles: [
                'SOLICITACAO-DE-ACESSO_SOLICITACOES-DE-ACESSO_DELETAR',
                'SOLICITACAO-DE-ACESSO_SOLICITACOES-DE-ACESSODELETAR',
                'SOLICITACAO-DE-ACESSO_SOLICITACOES-DE-ACESSOVISUALIZAR'
            ],
            solicitacaoDeAcesso: {
                visualizar: ['SOLICITACAO-DE-ACESSO_SOLICITACOES-DE-ACESSOVISUALIZAR'],
                salvar: ['SOLICITACAO-DE-ACESSO_SOLICITACOES-DE-ACESSO_DELETAR'],
                deletar: ['SOLICITACAO-DE-ACESSO_SOLICITACOES-DE-ACESSODELETAR']
            }
        }
    }
};
