import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'solicitacao-acesso'
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    },
    {
        path: 'solicitacao-acesso',
        loadChildren: () =>
            import('./pages/solicitacao-acesso/solicitacao-acesso.module').then(
                (m) => m.SolicitacaoAcessoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            role: menuRoles.itensMenu.solicitacaoDeAcesso.solicitacaoDeAcesso.visualizar
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
